import React from "react";
import { DEFAULT_FALLBACK } from "services/Constants/constant";

class ErrorWrapper extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log("----Errors--- : ", error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || DEFAULT_FALLBACK;
    }
    return this.props.children;
  }
}

export default ErrorWrapper;
