import React from 'react';
import { MainContainer, Box, Title } from "./style";
import { Dropdown } from './components/Dropdown';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filterActions as ClinicalActions } from "store/ClinicalTrials/filtersReducer";
import { getDiseaseHierarchy, regexMatch } from './util';
import { ROUTE_CLINICAL_TRIALS } from 'services/Constants/constant';


export default function ExCodePage({ data, searchText }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleBoxClick = (event, disease, level, ifTrials) => {
        event.stopPropagation();

        /* Clearing Filter values before CT-Page */
        dispatch(ClinicalActions.setDefault());

        if (!ifTrials) return;

        dispatch(ClinicalActions.changeFilter({ key: "Disease", value: [ disease ] }));
        const hierarchy = getDiseaseHierarchy(event, disease, level);
        navigate(ROUTE_CLINICAL_TRIALS(disease), { state: { hierarchy: hierarchy, level: `level_${level}`}});
    }

    const renderBoxes = (data, level) => {
        const adjustedLevel = level - 1;
        return data.map((entry, index) => {
            const { name, children, ifTrials } = entry;

            return (
                <React.Fragment key={`level-${adjustedLevel}-${index}`}>
                    { level >= 3 ?
                        <Dropdown
                            searchText={searchText}
                            className={"current"}
                            name={name}
                            level={adjustedLevel}
                            ifDisabled={children.length === 0}
                            children={children}
                            ifTrials={ifTrials}
                        />
                        : <Box
                            key={`level-${adjustedLevel}-${index}`} 
                            level={level}
                            lastLevel={children.length===0}
                            onClick={(e) => handleBoxClick(e, name, adjustedLevel, ifTrials)}
                            className={"current"}
                            match={searchText.length >= 3 && regexMatch(searchText, name)}
                            disabled={!ifTrials}
                        >
                            <Title>{`${name}`}<br /></Title>
                            { Array.isArray(children) && renderBoxes(children, level + 1) }
                        </Box>
                    }
                </React.Fragment>
            )
        });
    };

    return <MainContainer>{renderBoxes(data, 1)}</MainContainer>;
}