import styled from "styled-components";

export const RequestInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
`;

export const RequestInfoText = styled.div`
  line-height: 1.2em;
  font-size: ${(props) => props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading3.fontWeight};
  color: ${(props) => props.theme.colors.text.disabledText};
  text-align: center;
  margin: 0 3vw;
`;
