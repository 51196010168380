import { media } from "assets/static";
import styled from "styled-components";
import auth_background from "assets/image/auth_background.png";
import trademarkLogo from "assets/image/sm_trademark.svg";

export const LogInContainer = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.isDeviceMobile ? '97vh' : '94.5vh'}; /* To exclude footer */
    background: #F7F7F7;
`

export const GreetingsArea = styled.div`
    width: 65vw;
    background-image: url(${auth_background});
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: 25% 26%;
`

export const GreetingsContainer = styled.div`
    width: 45.11vw;
    ${'' /* height: 14.4vh; */}
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(40px);

    padding: 1.6vh 1.85vw 1.6vh 3.12vw;

    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 16.3vh;
`

export const GreetingsHeading = styled.span`
    width: fit-content;
    color: ${props => props.theme.colors.text.generalText};
    
    font-size: ${props => props.theme.heading1.fontSize};
    font-weight: ${props => props.theme.heading0.fontWeight};
    line-height: normal;
`

export const GreetingsSubHeading = styled.div`
    color: ${props => props.theme.colors.text.secondaryText};
    
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading0.fontWeight};
    line-height: normal;
`

export const InfoArea = styled.main`
    width: 35vw;
    background: white;
    display: flex;
    justify-content: center;
`

export const InfoAreaForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3.5vw;
`

export const InfoAreaHeadingContainer = styled.div`
    display: flex;
    align-items: center;
    color: #30466C;
    font-size: 2rem;
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
    gap: 5px;
`

export const InfoAreaSubHeading = styled.p`
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: ${props => props.theme.colors.text.generalText};
`

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '2vh 0'};
`

export const WiserLogo = styled.img`
    height: 6.8vh;
    cursor: pointer;


    ${media.monitor} {
        height: 5.4vh;
    }

    ${media.large_desktop} {
        height: 5vh;
    }

    ${media.laptop} {
        height: 3.8vh;
    }

`;

export const TrademarkLogo = styled.div`
    background-image: url(${trademarkLogo});
    background-repeat: no-repeat;
    background-size: 100%;
    width: ${props => props.size ? props.size : '16px'};
    height: ${props => props.size ? props.size : '16px'};
`