import React, { useEffect, useState } from "react";

import AnyChart from "anychart-react";
import anychart from "anychart";

import "./styles.css";

const SunburstAnyChart = (props) => {
  const dataTree = anychart.data.tree(props.data, "as-table");
  const chart = anychart.sunburst(dataTree);
  const [chartAny, setChartAny] = useState();
  useEffect(() => {
    console.log(props.data);
    chart.calculationMode("parent-independent");

    chart.labels().useHtml(true);
    chart.labels().format("{%Name}");

    chart.tooltip().format("{%Name}: {%Value}");

    chart.background().fill("#FAFAF9 0.2");
    chart.fill(function () {
      return anychart.color.darken(this.parentColor, 0.005);
    });

    chart.container(props.chatID);
    var legend = chart.legend();
    legend.enabled(true);
    legend.maxWidth("100%");
    legend.position("bottom");
    legend.positionMode("outside");
    legend.drag(true);
    legend.itemsLayout("horizontalExpandable");
    legend.align("center");
    legend.padding(3);
    legend.itemsSpacing(4.5);

    legend.itemsFormatter(function (items) {
      props.data.map((item) => {
        if (item["parent"] === "root") {
          items.push({
            text: item["name"],
            fontSize: "0.45rem",
            iconFill: item["fill"],
            iconTextSpacing: 4,
          });
        }
      });

      return items;
    });

    chart.level(0).thickness("44%");
    chart.normal().stroke("#FAFAF9", 0.5);
    chart.hovered().stroke("#FAFAF9", 1.5);

    setChartAny(chart.draw());
  }, [props.data]);

  return <AnyChart instance={chartAny} id={props.chatID} />;
};

export default React.memo(SunburstAnyChart);
