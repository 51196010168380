import {
  WiserLogo,
  InfoAreaForm,
  InfoAreaHeadingContainer,
  InfoAreaSubHeading,
  OptionsContainer,
  MInfoAreaForm,
  MInfoAreaHeading,
  MInfoAreaSubHeading,
  MInputFieldsContainer,
} from "./styles";

import ProductLogo from "../../assets/image/gaa-logo.png";
import {
  Checkbox,
  Error,
  InputField,
  RoundedButton,
  RoundedInputField,
  ShowOrHideOption,
  TextButton,
} from "components";
import Layout from "../../layout/authLayout";
import { useNavigate } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MGaaLogo } from "./styles/style.Auth.Mobile";
import { TrademarkLogo } from "./styles/style.Auth.Screen";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateField } from "../../services/Utils/Validator";
import { useAuth } from "../../contexts/AuthContext";
import { userLogin } from "../../store/Requests/auth";
import {
  ROUTE_SIGNUP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
} from "services/Constants/constant";
import { handleGAAIconClick } from "./utils";

function LogInForm(props) {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const isDeviceMobile = width < height;

  const { userSignIn } = useAuth();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const loginButtonRef = useRef(null);
  const didMountRef = useRef(false);

  const dispatch = useDispatch();

  const FORM_FIELD_TYPE_email = "email";
  const FORM_FIELD_TYPE_password = "password";
  const FORM_FIELD_PLACEHOLDER_email = "Company email ID";
  const FORM_FIELD_PLACEHOLDER_password = "Password";
  const TEXT_login = "Log In";
  const TEXT_remember_me = "Remember me";
  const TEXT_forgot_password = "Forgot password?";
  const TEXT_signup = "First time? Sign up instead";
  const TEXT_FORM_subheading =
    "This is a secure system. Please enter company credentials in order to access your dashboard.";

  const initialState = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusedFields, setFocusedFields] = useState({
    email: false,
    password: false,
  });
  const [fieldsFocusedAtLeastOnce, setFieldsFocusedAtLeastOnce] = useState({
    email: false,
    password: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    areValid: true,
    errors: {
      email: {
        isValid: true,
        error: null,
      },
      password: {
        isValid: true,
        error: null,
      },
      others: {
        isValid: true,
        error: null,
      },
    },
  });

  const handleInputChange = (label, event) => {
    setData({
      ...data,
      [label]: event.target.value,
    });
  };

  const handleFocusChange = (label, value) => {
    setFocusedFields({
      ...focusedFields,
      [label]: value,
    });

    if (value && !fieldsFocusedAtLeastOnce.label) {
      setFieldsFocusedAtLeastOnce({
        ...fieldsFocusedAtLeastOnce,
        [label]: value,
      });
    }
  };

  const validateInputFields = (emailRef, pwdRef) => {
    let output = { areValid: true, errors: "" };

    const emailValidationResult = validateField(FORM_FIELD_TYPE_email, [
      emailRef,
    ]);
    const pwdValidationResult = validateField(FORM_FIELD_TYPE_password, [
      pwdRef,
    ]);

    if (emailValidationResult.isValid && pwdValidationResult.isValid) {
      output = {
        areValid: true,
        errors: {
          email: emailValidationResult,
          password: pwdValidationResult,
        },
      };
    } else {
      output = {
        areValid: false,
        errors: {
          email: emailValidationResult,
          password: pwdValidationResult,
        },
      };
    }

    return output;
  };

  useEffect(() => {
    if (didMountRef.current) {
      const areInputFieldsValid = validateInputFields(
        emailInputRef,
        passwordInputRef
      );
      if (areInputFieldsValid.areValid == false) {
        setIsSubmitting(false);
        setErrorMessages({
          ...errorMessages,
          areValid: areInputFieldsValid.areValid,
          errors: {
            ...errorMessages.errors,
            email: {
              ...errorMessages.errors.email,
              isValid: areInputFieldsValid.errors.email.isValid,
              error: areInputFieldsValid.errors.email.error,
            },
            password: {
              ...errorMessages.errors.password,
              isValid: areInputFieldsValid.errors.password.isValid,
              error: areInputFieldsValid.errors.password.error,
            },
            others: {
              ...errorMessages.errors.others,
              isValid: true,
              error: null,
            },
          },
        });
      } else {
        setIsSubmitting(true);
        setErrorMessages({
          ...errorMessages,
          areValid: true,
          errors: {
            ...errorMessages.errors,
            email: {
              ...errorMessages.errors.email,
              isValid: true,
              error: null,
            },
            password: {
              ...errorMessages.errors.password,
              isValid: true,
              error: null,
            },
            others: {
              ...errorMessages.errors.others,
              isValid: true,
              error: null,
            },
          },
        });
      }
    } else {
      didMountRef.current = true;
      loginButtonRef.current.disabled = true;
    }
  }, [data]);

  const handleSubmit = () => {
    if (isSubmitting == false) return;

    setLoading(true);
    dispatch(
      userLogin({
        email: data.email,
        password: data.password,
        shouldRememberMe: data.rememberMe,
      })
    )
      .unwrap()
      .then((resJson) => {
        userSignIn(resJson);
        setLoading(false);
        navigate(ROUTE_HOME);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setErrorMessages({
          ...errorMessages,
          areValid: false,
          errors: {
            ...errorMessages.errors,
            others: {
              ...errorMessages.errors.others,
              isValid: false,
              error: error.statusText || error.message,
            },
          },
        });
      })
      .finally(() => setLoading(false));
  };

  const toggleRememberMe = () => {
    setData({
      ...data,
      rememberMe: !data.rememberMe,
    });
  };

  return (
    <Layout
      isLoaderDisplay={loading}
      footerRelative={true}
      isDeviceMobile={isDeviceMobile}
    >
      {isDeviceMobile ? (
        <>
          <MGaaLogo onClick={handleGAAIconClick} />
          <MInfoAreaForm>
            <div style={{ display: "flex", gap: "5px" }}>
              <MInfoAreaHeading>Clinical Trials WISE-R</MInfoAreaHeading>
              <TrademarkLogo />
            </div>
            <MInfoAreaSubHeading>{TEXT_FORM_subheading}</MInfoAreaSubHeading>

            <MInputFieldsContainer>
              <RoundedInputField
                placeholder={FORM_FIELD_PLACEHOLDER_email}
                type={FORM_FIELD_TYPE_email}
                required={true}
                value={data.email}
                innerRef={emailInputRef}
                className={errorMessages.errors.email.isValid ? "" : "invalid"}
                onChangeCallback={(e) => {
                  handleInputChange(FORM_FIELD_TYPE_email, e);
                }}
                onFocusCallback={() => {
                  handleFocusChange(FORM_FIELD_TYPE_email, true);
                }}
                onBlurCallback={() => {
                  handleFocusChange(FORM_FIELD_TYPE_email, false);
                }}
                keyDownCallback={handleSubmit}
              />
              <Error
                isVisible={
                  !(
                    errorMessages.errors.email.isValid || focusedFields.email
                  ) && fieldsFocusedAtLeastOnce.email
                }
                error={errorMessages.errors.email.error}
                margin={"0.5vh 3vw"}
              />

              <RoundedInputField
                placeholder={FORM_FIELD_PLACEHOLDER_password}
                type={FORM_FIELD_TYPE_password}
                required={true}
                value={data.password}
                innerRef={passwordInputRef}
                className={
                  errorMessages.errors.password.isValid ? "" : "invalid"
                }
                onChangeCallback={(e) => {
                  handleInputChange(FORM_FIELD_TYPE_password, e);
                }}
                onFocusCallback={() => {
                  handleFocusChange(FORM_FIELD_TYPE_password, true);
                }}
                onBlurCallback={() => {
                  handleFocusChange(FORM_FIELD_TYPE_password, false);
                }}
                keyDownCallback={handleSubmit}
                inputOption={
                  <div style={{ margin: "0 0 0 -6vw" }}>
                    <ShowOrHideOption fieldRef={passwordInputRef} />
                  </div>
                }
              />
              <Error
                isVisible={
                  !(
                    errorMessages.errors.password.isValid ||
                    focusedFields.password
                  ) && fieldsFocusedAtLeastOnce.password
                }
                error={errorMessages.errors.password.error}
                margin={"0.5vh 3vw"}
              />
              <Error
                isVisible={!errorMessages.errors.others.isValid}
                error={errorMessages.errors.others.error}
                margin={"0.5vh 3vw"}
              />
            </MInputFieldsContainer>

            <OptionsContainer padding={"0 0 2vh 0"}>
              <Checkbox
                label={TEXT_remember_me}
                fontSize={"1.2rem"}
                value={data.rememberMe}
                handleChange={toggleRememberMe}
              />
              <TextButton
                text={TEXT_forgot_password}
                fontSize={"1.2rem"}
                handleClick={() => navigate(ROUTE_FORGOT_PASSWORD)}
              />
            </OptionsContainer>
            <RoundedButton
              // width={'7.8vw'}
              height={"5.2vh"}
              fontSize={"1.8rem"}
              textColor={"white"}
              backgroundColor={"#23647C"}
              borderRadius={"24px"}
              disabled={isSubmitting ? false : true}
              innerRef={loginButtonRef}
              handleClick={handleSubmit}
            >
              {TEXT_login}
            </RoundedButton>
            <TextButton
              style={{ marginTop: "2.5vh" }}
              fontSize={"1.2rem"}
              text={TEXT_signup}
              handleClick={() => navigate(ROUTE_SIGNUP)}
            />
          </MInfoAreaForm>
        </>
      ) : (
        <>
          <InfoAreaForm>
            <InfoAreaHeadingContainer>
              <WiserLogo
                id="org-logo"
                src={ProductLogo}
                alt="GAA"
                onClick={handleGAAIconClick}
              />
              <div>Log in</div>
            </InfoAreaHeadingContainer>
            <InfoAreaSubHeading>{TEXT_FORM_subheading}</InfoAreaSubHeading>

            <InputField
              name={FORM_FIELD_PLACEHOLDER_email}
              type={FORM_FIELD_TYPE_email}
              value={data.email}
              innerRef={emailInputRef}
              required={true}
              className={errorMessages.errors.email.isValid ? "" : "invalid"}
              onChangeCallback={(e) => {
                handleInputChange(FORM_FIELD_TYPE_email, e);
              }}
              onFocusCallback={() => {
                handleFocusChange(FORM_FIELD_TYPE_email, true);
              }}
              onBlurCallback={() => {
                handleFocusChange(FORM_FIELD_TYPE_email, false);
              }}
              keyDownCallback={handleSubmit}
            />
            <Error
              isVisible={
                !(errorMessages.errors.email.isValid || focusedFields.email) &&
                fieldsFocusedAtLeastOnce.email
              }
              error={errorMessages.errors.email.error}
            />

            <InputField
              name={FORM_FIELD_PLACEHOLDER_password}
              type={FORM_FIELD_TYPE_password}
              value={data.password}
              innerRef={passwordInputRef}
              required={true}
              className={errorMessages.errors.password.isValid ? "" : "invalid"}
              onChangeCallback={(e) => {
                handleInputChange(FORM_FIELD_TYPE_password, e);
              }}
              onFocusCallback={() => {
                handleFocusChange(FORM_FIELD_TYPE_password, true);
              }}
              onBlurCallback={() => {
                handleFocusChange(FORM_FIELD_TYPE_password, false);
              }}
              keyDownCallback={handleSubmit}
              inputOption={
                <div style={{ margin: "0 0 0 1vw" }}>
                  <ShowOrHideOption fieldRef={passwordInputRef} />
                </div>
              }
              options={
                <TextButton
                  text={TEXT_forgot_password}
                  handleClick={() => navigate(ROUTE_FORGOT_PASSWORD)}
                />
              }
            />
            <Error
              isVisible={
                !(
                  errorMessages.errors.password.isValid ||
                  focusedFields.password
                ) && fieldsFocusedAtLeastOnce.password
              }
              error={errorMessages.errors.password.error}
            />
            <Error
              isVisible={!errorMessages.errors.others.isValid}
              error={errorMessages.errors.others.error}
            />

            <OptionsContainer>
              <Checkbox
                label={TEXT_remember_me}
                value={data.rememberMe}
                handleChange={toggleRememberMe}
              />
              <RoundedButton
                width={"7.8vw"}
                height={"4.5vh"}
                textColor={"white"}
                backgroundColor={"#23647C"}
                borderRadius={"14px"}
                innerRef={loginButtonRef}
                disabled={isSubmitting ? false : true}
                handleClick={handleSubmit}
              >
                {TEXT_login}
              </RoundedButton>
            </OptionsContainer>
          </InfoAreaForm>
          <TextButton
            style={{ position: "absolute", bottom: "9vh" }}
            text={TEXT_signup}
            handleClick={() => navigate(ROUTE_SIGNUP)}
          />
        </>
      )}
    </Layout>
  );
}

export default LogInForm;
