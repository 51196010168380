export const regexMatch = (pattern, disease) => {
    return new RegExp(pattern, "i").test(disease)
}

export const getDiseaseHierarchy = (event, disease, level) => {
    let hierarchy = [];
    hierarchy.push(disease);

    let target = event.currentTarget;
    for (let index = level -1; index >= 1; index--) {
        target = target.parentNode;
        hierarchy.push(target.innerText.split('\n')[0]);
    }
    return hierarchy.reverse(); // in order of grandparent to selected-disease
}
