import styled from "styled-components";

export const CollapsibleCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CollapsibleTileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${(props) => (props.isTileOpen ? "2%" : "0")};
  height: ${(props) => (props.isTileOpen ? "72%" : "14%")};

  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.showBottomBorder ? "1px solid rgb(196, 196, 196, 0.3)" : ""};

  overflow: hidden;
  transition: height 0.5s ease-out;
`;

export const CollapsibleTileHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-size: ${(props) => props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  cursor: pointer;
`;

export const ChartDisease = styled.p`
  margin: 0.4vh 0 0 0;
  font-size: 0.55rem;
  color: black;
`;

export const CollapsibleTileHeaderOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CollapsibleTileStateIcon = styled.span`
  display: flex;
  transform: ${(props) =>
    props.isTileOpen ? "rotate(0deg)" : "rotate(180deg)"};
  transition: transform 0.4s;
`;

export const CollapsibleTileBody = styled.div`
  display: ${(props) => (props.isTileOpen ? "flex" : "none")};
  /* flex-direction: column; */
  width: 100%;
  height: 81.8%;
  margin: 2vh 1vw;
  overflow: hidden;
`;
