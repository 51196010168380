import React, { useEffect, useState, useRef } from 'react';
import { MainContainer, Box, Title } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filterActions as ClinicalActions } from "store/ClinicalTrials/filtersReducer";
import { getDiseaseHierarchy, regexMatch } from './util';
import ErrorWrapper from 'middleware/errorWrapper';
import { ROUTE_CLINICAL_TRIALS } from 'services/Constants/constant';


const DiseasePage = ({ data, searchText, activeTab }) => {
    return <NestedBoxes data={data} searchText={searchText} key={activeTab} />
}


const NestedBoxes = ({ data, searchText }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ containerHeight, setContainerHeight ] = useState(data.length * 300 + 100);
    const containerRef = useRef(null);

    const search = (item, target) => {
        const [ disease, children ] = Object.entries(item)[0];
        let score = regexMatch(target, disease) ? 1 : 0;
        for (const child of children) {
            score += search(child, target);
        }
        return score;
    }

    const handleBoxClick = (event, disease, level, ifTrials) => {
        event.stopPropagation();

        /* Clearing Filter values before CT-Page */
        dispatch(ClinicalActions.setDefault());

        if (!ifTrials) return;

        dispatch(ClinicalActions.changeFilter({ key: "Disease", value: [ disease ] }));
        const hierarchy = getDiseaseHierarchy(event, disease, level);
        navigate(ROUTE_CLINICAL_TRIALS(disease), { state: { hierarchy: hierarchy, level: `level_${level}`}});
    }

    useEffect(() => {
        const contentHeight = containerRef.current.scrollHeight;
        setContainerHeight(Math.max(data.length * 500, contentHeight));
    });

    const renderBoxes = (data, level) => {
        return data.map((entry, index) => {
            const { name, children, ifTrials } = entry;

            return (
                <ErrorWrapper key={`ew-level-${level}-${index}`}>
                    <Box
                        key={`level-${level}-${index}`}
                        level={level}
                        match={searchText.length >= 3 && regexMatch(searchText, name)}
                        onClick={(e) => handleBoxClick(e, name, level, ifTrials)}
                        className={"current"}
                        disabled={!ifTrials}
                    >
                        <Title>{`${name}`} <br /></Title>
                        {Array.isArray(children) && renderBoxes(children, level + 1)}
                    </Box>
                </ErrorWrapper>
            )
            });
        };

    return <MainContainer style={{ height: containerHeight }} ref={containerRef}>{renderBoxes(data, 1)}</MainContainer>;
};

export default DiseasePage;