import RoundedButton from "../../Buttons/roundedButton";
import { RequestInfoContainer, RequestInfoText } from "./style.requestInfo";
import { LINK } from "services/Constants/constant";

const RequestInfo = (props) => {


    return (
        <RequestInfoContainer>
            <RequestInfoText>
                Can’t find what you’re looking for? Reach out to us to request more information on the disease.
            </RequestInfoText>
            <RoundedButton
                border={'1px solid rgba(106, 177, 218, 0.4)'}
                boxShadow={'3px 12px 20px rgba(88, 158, 195, 0.15)'}
                handleClick={() => window.open(LINK)}>
                <div>
                    Request Information
                </div>
            </RoundedButton>
        </RequestInfoContainer>
    );
}

export default RequestInfo;