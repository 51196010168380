import React, { Children, useState } from "react";
import GridCard from "../gridCard";
import CollapsibleTile from "./CollapsibleTile";
import { CollapsibleCardWrapper } from "./style.collapsibleCard";
import ErrorWrapper from "middleware/errorWrapper";

const CollapsibleCard = (props) => {
  const [tileState, setTileState] = useState([true, false, false]);

  const handleClick = (key) => {
    let newTileState = [false, false, false];
    newTileState[key] = true;

    setTileState(newTileState);
  };

  return (
    <ErrorWrapper>
      <GridCard
        gridRow={props.gridRow}
        gridColumn={props.gridColumn}
        isDataAvailable={props.isDataAvailable}
        noDataText={props.noDataText}
        isBlank
      >
        <CollapsibleCardWrapper>
          {Children.map(props.children, (child, index) => {
            return (
              <CollapsibleTile
                keyValue={index}
                title={props.titles[index]}
                isLastTile={index === props.titles.length - 1}
                isTileOpen={tileState[index]}
                info={tileState[index] ? props.info[0] : props.info[1]}
                handleTileClick={handleClick}
                chartDisease={props.chartDisease}
              >
                {child}
              </CollapsibleTile>
            );
          })}
        </CollapsibleCardWrapper>
      </GridCard>
    </ErrorWrapper>
  );
};

export default CollapsibleCard;
