import { Breadcrumb, Link, Typography } from '../style';
import withRouter from '../../services/Utils/ComponentWrapper';
import { TrademarkLogo } from 'pages/Auth/styles/style.Auth.Screen';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { filterActions as ClinicalActions } from "../../store/ClinicalTrials/filtersReducer";

const getDisplayName = (input_pathNames) => {
    // Cater for the path of list page
    if (input_pathNames.length >= 2 && input_pathNames[1] === "list") {
        input_pathNames[1] += " of all ";
        switch (input_pathNames[0]) {
            case "clinicalTrials":
                input_pathNames[1] += "Trials";
                break;
            case "vaccineAvailability":
                input_pathNames[1] += "Vaccines";
                break;
            case "funding":
                input_pathNames[1] += "Projects";
                break;
            default:
                break;
        }
    }
    return input_pathNames;
}

const Breadcrumbs = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const histories = props.history;
    const pathname = props.location.pathname;
    const pathNames = getDisplayName(pathname.split('/').filter(x => x));
    
    const formatPathName = (name) => {
        const tempName = name.split("-");
        let dispName = tempName.join(" ");

        let modifiedTempName = dispName.split('%20');
        dispName = modifiedTempName.join(" ");
        
        modifiedTempName = dispName.split('%');
        dispName = modifiedTempName.join("");

        return dispName;
    }
    
    return (
        <Breadcrumb>
            <span>{'Clinical Trial WISE-R'}&nbsp;<TrademarkLogo size={`12px`}/>&nbsp;{`>`}</span>
            {
                pathNames.map((name, index) => {
                    // index = 1 i.e. CT page
                    if(index != 1){

                        const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                        const lastIndex = pathNames.length - 1 === index;
                        const dispName = formatPathName(name);

                        return (
                            lastIndex ?
                                <Typography key={index}>{dispName[0].toUpperCase() + dispName.substring(1)}</Typography>
                                :
                                <Link key={index} onClick={() => { histories(routeTo, {state: props.location.state}) }}>
                                    {dispName[0].toUpperCase() + dispName.substring(1)}
                                </Link>
                        );                    
                    }else{
                        
                        return (
                            props.location.state.hierarchy.map((entry, index2) => {
                                let routeTo = [];
                                routeTo.push(pathNames[0]);
                                routeTo.push(entry.replaceAll(" ", "%20"));
                                routeTo = `/${routeTo.join('/')}`;
                                const lastIndex = pathNames.length - 1 === index && props.location.state.hierarchy.length - 1 === index2;
                                const dispName = formatPathName(entry);

                                return (
                                    lastIndex ?
                                        <Typography 
                                            key={`level_${index2+1}`}
                                            title={entry}>
                                            {dispName[0].toUpperCase() + dispName.substring(1)}
                                        </Typography>
                                    :
                                        <Link 
                                            key={`level_${index2+1}`} 
                                            title={entry}
                                            onClick={() => {
                                                dispatch(ClinicalActions.changeFilter({ key: 'Disease', value: [entry] }));
                                                navigate( routeTo, 
                                                            { state: {
                                                                hierarchy: props.location.state.hierarchy.slice(0,index2+1),
                                                                level: `level_${index2+1}`
                                                            }}
                                                        ) 
                                            }}
                                        >
                                            {dispName[0].toUpperCase() + dispName.substring(1)}
                                        </Link>
                                )
                            })
                        )
                    }
                })
            }
        </Breadcrumb>
    );
}

export default withRouter(Breadcrumbs);