import React from "react";
import {
  FooterContainer,
  FooterHeading,
  FooterLink,
  FooterLinkSeparator,
  LinkContainer,
} from "../style";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../assets/image/gaa-logo.png";
import {
  LINK,
  LINK_M,
  LINK_GAA_about,
} from "../../services//Constants/constant";

import useWindowDimensions from "hooks/useWindowDimensions";

const Footer = (props) => {
  const { width, height } = useWindowDimensions();
  const isDeviceMobile = width < height;
  return (
    <FooterContainer relative={props.relative} showSingleLine={isDeviceMobile}>
      {/* Footer: Company & copyright information */}
      <FooterHeading>
        <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
        GLOBAL ACTION ALLIANCE&nbsp;&nbsp;|&nbsp;&nbsp;Powered by WISE-R &#8480;
        a Global Action Alliance Platform
      </FooterHeading>
      {!isDeviceMobile && (
        <LinkContainer>
          <FooterLink target="_blank" rel="noreferrer" href={LINK_GAA_about}>
            About GAA
          </FooterLink>
          <FooterLinkSeparator>|</FooterLinkSeparator>
          <FooterLink target="_blank" rel="noreferrer" className="disabled">
            Privacy Policy
          </FooterLink>
          <FooterLinkSeparator>|</FooterLinkSeparator>
          <FooterLink target="_blank" rel="noreferrer" className="disabled">
            Terms and Conditions
          </FooterLink>
          <FooterLinkSeparator>|</FooterLinkSeparator>
          <FooterLink target="_blank" rel="noreferrer" className="disabled">
            Located in Washington DC
          </FooterLink>
          <FooterLinkSeparator>|</FooterLinkSeparator>
          <FooterLink href={LINK_M}>
            Contact Us at contact@globalactionalliance.net
          </FooterLink>
          <FooterLinkSeparator>|</FooterLinkSeparator>
          <FooterLink href={LINK}>Share additional data</FooterLink>
        </LinkContainer>
      )}
    </FooterContainer>
  );
};

export default Footer;
