import { createSlice } from "@reduxjs/toolkit";

export const defaultState = {
  filters: {
    Disease: [],
    Sponsor: [],
    Locations: [],
    SurroundCountries: [],
    Phase: [],
    Active: [],
    Year_Started: [],
    Study_Type: [],
    Year_Completed: [],
  },
};

const resetFilters = [
  "Locations",
  "Sponsor",
  "Phase",
  "Active",
  "Year_Started",
  "Study_Type",
  "Year_Completed",
];

const filters = createSlice({
  name: "filters/clinical",
  initialState: defaultState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters[action.payload.key] = action.payload.value;
    },
    setDefault: (state, action) => {
      resetFilters.map((item) => {
        state.filters[item] = defaultState.filters[item];
      });
    },
  },
});

export const filterActions = filters.actions;
export default filters.reducer;
