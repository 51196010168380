import React, { useState, useEffect, useRef } from "react";
import {
  FilterWrapper,
  FilterLabel,
  FilterIcon,
  OptionsMenu,
  FilterOptionWrapper,
  FilterOptions,
  SingleFill,
  FilterSearch,
} from "./style";

import { BarLoader } from "react-spinners";

import { BsTriangleFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { CiSearch } from "react-icons/ci";
import { IoSearch } from "react-icons/io5";

const Filter = ({
  filterLabel,
  loading,
  filterOption,
  filtersSelected,
  handleClickFilters,
  isSingleSelect = true,
}) => {
  const [filterState, setFilterState] = useState(false);
  const [filterSearchVal, setFilterSearchVal] = useState("");
  const filterRef = useRef(null);
  // const filterSearchRef = useRef("");

  const close = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", close, true);

    return () => {
      window.removeEventListener("click", close, true);
    };
  }, []);

  const clickFilter = () => {
    setFilterState(!filterState);
  };

  const clickHandler = (val, isClick) => {
    if (!isClick) {
      alert("Option is not selectable");
      return;
    }
    // setFilterState(!filterState);

    if (isSingleSelect) {
      handleClickFilters(`${filterLabel}`, [val]);
      return;
    }
    if (filterLabel === "Disease") {
      handleClickFilters(`${filterLabel}`, [val]);
      return;
    }
    if (
      filtersSelected[filterLabel].find((element) => {
        return element == val;
      }, val) // If new value is already in the selected filter
    ) {
      var filtersRm = [...filtersSelected[filterLabel]];
      const index = filtersRm.indexOf(val);

      if (index > -1) {
        filtersRm.splice(index, 1);
      }

      handleClickFilters(`${filterLabel}`, [...filtersRm]);
    } else {
      // If val is found in filtersSelected[filterLabel] array
      handleClickFilters(`${filterLabel}`, [
        ...filtersSelected[filterLabel],
        val,
      ]);
    }
  };

  const clearFilter = () => {
    handleClickFilters(`${filterLabel}`, []);
    return;
  };

  const onFilterSearchValueChange = (e) => {
    console.log(e.target.value);
    // e.prevenDefault();
    setFilterSearchVal(e.target.value);
  };

  return (
    <FilterWrapper
      ifOpen={filterState}
      ifApplied={filtersSelected[filterLabel].length > 0}
      filterState={filterState}
      ref={filterRef}
    >
      <FilterLabel>
        {filterState == true &&
        isSingleSelect == false &&
        filterOption.length > 15 ? (
          <span style={{ marginBottom: "-.5vh" }}>
            <IoSearch />
            <FilterSearch
              type="text"
              placeholder="Search"
              value={filterSearchVal}
              onChange={(e) => onFilterSearchValueChange(e)}
            />
          </span>
        ) : (
          <span onClick={clickFilter}>{filterLabel.split("_").join(" ")}</span>
        )}
        <FilterIcon ifOpen={filterState} onClick={clickFilter}>
          <BsTriangleFill />
        </FilterIcon>
      </FilterLabel>
      {filterState && (
        <OptionsMenu>
          {loading ? (
            <BarLoader
              color="#549EB0"
              height={5}
              speedMultiplier={0.8}
              width={80}
              cssOverride={{ margin: "2vh 0px 2vh 0vw" }}
            />
          ) : (
            <FilterOptionWrapper>
              {filterLabel != "Disease" &&
                filtersSelected[filterLabel].length > 0 && (
                  <li onClick={clearFilter}>Reset</li>
                )}

              {filterOption &&
                filterOption
                  .filter((str) =>
                    str[0].toLowerCase().includes(filterSearchVal.toLowerCase())
                  )
                  .map((ele) => {
                    return (
                      <FilterOptions
                        ifSelectable={ele[1]}
                        ifApplied={filtersSelected[filterLabel].length > 0}
                        onClick={() => clickHandler(ele[0], ele[1])}
                        isSingleSelect={isSingleSelect}
                      >
                        <span>
                          {isSingleSelect == false
                            ? filtersSelected[filterLabel].indexOf(ele[0]) >
                                -1 && <TiTick />
                            : filtersSelected[filterLabel].indexOf(ele[0]) >
                                -1 && <SingleFill />}
                        </span>

                        <span>{ele[0]}</span>
                      </FilterOptions>
                    );
                  })}
            </FilterOptionWrapper>
          )}
        </OptionsMenu>
      )}
    </FilterWrapper>
  );
};

export default Filter;
