import React from 'react'
import { MODE, PATH, PORT } from "middleware/api";
import Layout from "layout/diseaseSelectLayout";
import { TopScrollBtn } from 'components';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { 
    CardSummaryStatus as SummaryStatus, 
    CardSummaryStatusText as SummaryStatusText, 
    CardTrialID as TrialID, 
    HeaderRow, 
    HeaderRowLastUpdatedOn, 
    HeaderRowTrialTitle, 
    HeadlineContainer, 
    CardTitle,
    CardBody,
    RowWrapper,
    RowProperty,
    RowValue,
    DetailsCardContainer,
    DetailsCardRow,
    DetailsCard
} from '../style.clinicalTrails';
import { useNavigate, useParams } from 'react-router';
import { getStatusColor } from '../utils/util';

function IndividualTrial(props) {
    
    const [ isLoading, setLoading ] = useState(false);
    const { currentUser, userLogOut } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    
    const [ expandedKeys , setExpandedKeys ] = useState([]);

    const TITLES = {
        Summary: "Summary of Clinical Trial Results",
        GeneralInformation: "General Information",
        StudyInformation: "Study Information",
        Status: "Status",
        Criteria: "Criteria",
        Outcome: "Outcome"
    }
    const TEXt_to_top = "To Top";
    const TEXT_last_updated_on = "Last updated on ";


    const [ data, setData ] = useState({
        Title : "",
        Status : "",
        TrialID : "",
        Last_Updated : "",
        Data : {
            Summary : "",
            General_Information : {
                Start_Date : "",
                Completion_Date : "",
                Primary_Sponsor : "",
                Phase : "",
                Study_Type : "",
                Target_Size : "",
                Age_Range : "",
                Conditions : [],
                Location : [],
                Countries : []
            },
            Study_Information : {
                Scientific_Title : "",
                Study_Results : "",
                Study_Design : "",
                Summary_Of_Product : "",
                Intervention : ""
            },
            Status : {
                Recruitment_Status : "",
                Recruitment_Period : "",
                Date_Enrollment : ""
            },
            Criteria : {
                Inclusion_Gender : "",
                Inclusion_Criteria : "",
                Exclusion_Criteria : ""
            },
            Outcome : {
                Primary_Outcome : "",
                Secondary_Outcome : ""
            }
        }
    });

    const fetchCall = () => {
        setLoading(true);
        
        const queryTrialData = async () => {
            
            const rawResponse = await fetch(
                // For local-file use: window.location.origin + `/ct_data.json`,
                `${MODE}://${PATH}:${PORT}/v1/clinicalTrials/detail/` + params.trialId,
                {
                method: "GET",
                headers: {
                    Authorization: `${currentUser.token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                }
            );
    
            if (!rawResponse.ok && rawResponse.status === 403) {
                userLogOut();
            }
            const results = await rawResponse.json();
    
            setData((prevData) => ({
                ...prevData,
                Title: results.Title,
                Status : results.Status,
                TrialID : results.TrialID,
                Last_Updated : results.Last_Updated,
                Data: results.Data
            }));
        }

        queryTrialData()
            .catch((err) =>
                console.error(`Error Occurred during fetch: ${err.message}`)
            )
            .finally(() => setLoading(false));
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    

    useEffect(() => {
        window.scrollTo(0,0); 
        fetchCall();
    }, [])

    const updateExpandedKeys = (element) => {
        if( expandedKeys.includes(element)){
            setExpandedKeys(prev => prev.filter( key => key !== element))
        }else{
            setExpandedKeys(prev => [...prev, element])
        }
    }

    const STRING_LENGTH_LIMIT = 500;

    const generateRows = (obj) => {

        return (
            <>
                {
                    Object.entries(obj).map((entry) => {
                        const value = Array.isArray(entry[1]) 
                                        ? ( entry[1].length > 0 ? entry[1].join(', ') : '-' )
                                        : ( entry[1] != "" ? entry[1] : '-' );
                        
                        return (
                            <RowWrapper key={entry[0]}>
                                <RowProperty>{entry[0].replace('_', ' ') + ': '}</RowProperty>
                                <RowValue>
                                    {
                                        value.length > STRING_LENGTH_LIMIT 
                                        ? (expandedKeys.includes(entry[0]) ? value : value.substring(0,STRING_LENGTH_LIMIT))
                                        : value
                                    }
                                </RowValue>
                                {
                                    value.length > STRING_LENGTH_LIMIT && 
                                        <span 
                                            style={{ cursor: 'pointer', color: '#6B8F9C' }} 
                                            onClick={() => updateExpandedKeys(entry[0])}>
                                                {expandedKeys.includes(entry[0]) ? " (...Show less)" : " (...Show more)"}
                                        </span>
                                }
                            </RowWrapper>
                        )
                    })
                }
            </>
        )
    }

    const [statusColor, statusTextColor] = getStatusColor(data.Status);

    return (
        <Layout 
            isLoaderDisplay={isLoading}
            title={"Clinical Trials"}
            footerRelative={true}
            headerOrder={"back-button | null | username-button"}
            onBackClickCallback={() => navigate(-1)}

        >
            <HeadlineContainer>
                <HeaderRow>
                    <HeaderRowTrialTitle>
                        {data.Title}
                    </HeaderRowTrialTitle>
                    <SummaryStatus
                        statusColor={statusColor}>
                        <SummaryStatusText
                            color={statusTextColor}>
                            { data.Status }
                        </SummaryStatusText>
                    </SummaryStatus>
                    <TrialID>{ data.TrialID }</TrialID>
                </HeaderRow>
                <HeaderRowLastUpdatedOn>
                    {TEXT_last_updated_on + data.Last_Updated}
                </HeaderRowLastUpdatedOn>
            </HeadlineContainer>

            <DetailsCardContainer>
                <DetailsCardRow>
                    <DetailsCard
                        width={'50%'}>
                        {/* Summary */}
                        <CardTitle>
                            {TITLES.Summary}
                        </CardTitle>
                        <CardBody>
                            {data.Data.Summary}
                        </CardBody>
                    </DetailsCard>
                    <DetailsCard>
                        {/* General Information */}
                    <CardTitle>
                        {TITLES.GeneralInformation}
                        </CardTitle>
                        <CardBody>
                            {generateRows(Object.fromEntries(Object.entries(data.Data.General_Information).splice(0, 7)))}
                            
                            <br /> {/* For Spacing */}

                            {generateRows(Object.fromEntries(Object.entries(data.Data.General_Information).splice(7)))}
                        </CardBody>
                    </DetailsCard>
                </DetailsCardRow>

                <DetailsCardRow>
                    <DetailsCard>
                        <CardTitle>
                            {TITLES.StudyInformation}
                        </CardTitle>
                        <CardBody>
                            {generateRows(data.Data.Study_Information)}
                        </CardBody>
                    </DetailsCard>
                </DetailsCardRow>

                <DetailsCardRow>
                    <DetailsCard
                        width={'50%'}
                        padding={'2.5vh 2vw'}>
                        <CardTitle>
                            {TITLES.Status}
                        </CardTitle>
                        <CardBody>
                            {generateRows(data.Data.Status)}
                        </CardBody>
                    </DetailsCard>
                    <DetailsCard>
                        <CardTitle>
                            {TITLES.Criteria}
                        </CardTitle>
                        <CardBody>
                            {generateRows(data.Data.Criteria)}
                        </CardBody>
                    </DetailsCard>
                </DetailsCardRow>

                <DetailsCardRow>
                    <DetailsCard>
                        <CardTitle>
                            {TITLES.Outcome}
                        </CardTitle>
                        <CardBody>
                            {generateRows(data.Data.Outcome)}
                        </CardBody>
                    </DetailsCard>
                </DetailsCardRow>

            </DetailsCardContainer>

            <TopScrollBtn label={TEXt_to_top} handleOnClick={scrollToTop} />
        </Layout>        
    )
}

export default IndividualTrial
