import styled from "styled-components";


export const DropdownWrapper = styled.div`
    border-radius: 24px;
    border: 1px solid rgba(106, 177, 218, 0.40);
    box-shadow: 3px 12px 20px 0px rgba(88, 158, 195, 0.15);
    padding: 11px 25px;
    margin: 5px;
    color: #23647C;
    box-sizing: border-box;
    width: 23vw;
    background-color: ${props => props.match 
        ? "#EEFCFE" 
        : ( props.childMatch && !props.ifOpen 
            ? "#EEFCFE" 
            : "#FAFAF9")
    };

    &:hover {
        background-color: ${props => !props.ifOpen && "#EEFCFE"};
    }

    &:active {
        background-color: ${props => !props.ifOpen && "#DBEAF3"};
    }
`


export const TopTitle = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    box-sizing: border-box;
    width: 100%;

    & > .arrow {
        color: ${props => props.ifDisabled && "#C4C4C4"};
        cursor: ${props => props.ifDisabled ? "not-allowed" : "pointer"};
    }

    ${
        props => props.ifTrials ? 
            "color: #468AAB; cursor: pointer;" 
            : "color: #6C7073;"
    }

    ${ props => props.match && " \
        border-radius: 14px; \
        border: 1.5px solid rgba(35, 100, 124, 0.50);\
        background: #EEFCFE;\
    " }

`


export const ArrowIcon = styled.span`
    font-size: ${(props) => props.theme.heading5.fontSize};
    transform: ${(props) => props.ifOpen ? "rotate(180deg)" : "rotate(90deg)"};
    transition: transform 0.3s;

    margin-right: 10px;
    color: #23647C;
`


export const Menu = styled.div`
    display: ${props => props.ifOpen ? 'block' : 'none'};
`
