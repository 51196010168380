import React, { useEffect, useState, useMemo, useDeferredValue } from 'react';
import DiseasePage from './DiseasePage';
import ExCodePage from './ExCodePage';
import Layout from "layout/diseaseSelectLayout";
import { NavBar, NavButton, Circle } from "./style";
import { regexMatch } from './util';
import { MODE, PATH, PORT } from "middleware/api.js";
import { TopScrollBtn } from "components";



const App = () => {
    const [ loading, setLoading ] = useState(false);
    const [ diseases, setDiseases ] = useState({});
    const [ activeTab, setActiveTab ] = useState(0); // index of currently active index
    const [ searchText, setSearchText ] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const rawResponse = await fetch(
                `${MODE}://${PATH}:${PORT}/v1/diseases`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                }
            ); 
            const results = await rawResponse.json(); // Receive filtered results
            setDiseases(results);
            setLoading(false);
        }

        fetchData();
    }, []);

    const sortHelper = (entry, target) => {
        let { name: disease, children } = entry;
        let score = 0;
        if (children != null) {
            let matched = [];
            for (const child of children) {
                const count = sortHelper(child, target);
                score += count;
                if (count > 0) matched.push(child);
            }
            
            const unmatched = children.filter(n => !matched.includes(n));
            entry.children = [ ...matched, ...unmatched ];
        }
        return score + (regexMatch(target, disease) ? 1 : 0);
    }

    const sort = (data) => {
        const sortedNewData = JSON.parse(JSON.stringify(data));
        const resultCounts = Array(sortedNewData.length).fill(0);
        let ifResult  = false;
        for (const [ i, entry ] of sortedNewData.entries()) {
            const topLevelName = entry.name;
            entry.name = ""; // remove name field so disease title isn't part of search
            resultCounts[i] = sortHelper(entry, searchText);
            if (resultCounts[i]) ifResult = true;
            entry.name = topLevelName;
        }

        if (ifResult) scrollTop(); // scroll to top if any current tab has result

        return [ resultCounts, sortedNewData ];
    }

    const deferredSearchText = useDeferredValue(searchText, { timeoutMs: 1000 }); 

    const [ counts, data ] = useMemo(() => {
        return searchText.length >= 3 ? sort(diseases) : [0, diseases];
    }, [deferredSearchText, diseases]);


    const handleSearchInput = (e) => {
        setSearchText(e.target.value);
    }

    function scrollTop() {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
        });
    };

    return (
        (Object.keys(data).length > 0) && 
            <Layout
                title={"Clinical Trials"}
                footerRelative={true}
                headerOrder={"gaa-logo | search-bar | username-button"}
                handleSearchInput={handleSearchInput}
            >

                <NavBar>
                    { diseases.map((disease, index) => {
                        return (
                            // Placing "Extension Code" tab at the end
                            <div 
                                key={disease._id}
                                style={{position: "relative", order: `${disease.isExtensionCode && 1}`}}>
                                { 
                                    activeTab !== index && counts[index] > 0 &&
                                        <Circle>{counts[index]}</Circle> 
                                } 
                                <NavButton 
                                    onClick={() => setActiveTab(index)} 
                                    ifSelected={activeTab===index}
                                >
                                    { disease.isExtensionCode ? "Other ICD Categories" : disease.name }
                                </NavButton>
                            </div>
                        )
                    }) }
                </NavBar>

                <TopScrollBtn margin={"-6vh 0 0 0"} label={"To Top"} handleOnClick={scrollTop} />

                {
                    data[activeTab].isExtensionCode ?
                        // wrap the data in array to conform to data structure for render
                        <ExCodePage data={ [ data[activeTab] ] } searchText={searchText} />
                        : <DiseasePage data={ data[activeTab].children } searchText={searchText} key={activeTab} />
                }

            </Layout>
    );
}



export default App;