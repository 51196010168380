import React from "react";
import { ChartContainer } from "./style.charts";
import { Bar } from "react-chartjs-2";
import { HORIZONTAL_BAR_DEFAULT_COLOR, LEGEND_TEXT_MAX_LENGTH } from "services/Constants/constant";
import { Tooltip } from "components/Maps/style.map";
import { MdSquare } from "react-icons/md";
import { useState } from "react";

const HorizontalBarChart = ({ data, chartHeight, chartWidth, color }) => {
  const default_color_arr = HORIZONTAL_BAR_DEFAULT_COLOR;
  const [tooltipData, setTooltipData] = useState({ label: '', color: '', point: {x: 0, y: 0} });

  const horizontalData = {
    labels: data.sponsorTypes,
    datasets: data.trialsData.map((element, index) => {
      return {
        axis: "y",
        label: `${element.disease 
                    ? element.disease 
                    : "Value"}`,
        data: element.trialsCount,
        skipNull: true,
        fill: false,
        backgroundColor: color ? color[index] : default_color_arr[index],
        borderColor: color ? color[index] : default_color_arr[index],
      };
    }),
  };

  const tooltipConfig = {
    backgroundColor: 'rgba(250, 250, 249, 1)',
    titleColor: '#164050',
    bodyColor: '#164050',
    titleFont: { family: 'Libre Franklin', weight: 'normal' },
    bodyFont: { family: 'Libre Franklin' },
    caretSize: 0,
    cornerRadius: 16,
    padding: 10,
    boxWidth: 16,
    boxHeight: 16,
    boxPadding: 5,
    borderColor: 'rgba(0,0,0, 0.1)',
    borderWidth: 2,
    callbacks: {
      title: function(context) {
        let title = context[0].label;
        let totalBarValue = Object.values(context[0].parsed._stacks.x._visualValues)
                                          .reduce((sum, x) => sum + x, 0)
                                          .toLocaleString("en-US");
        return title + `: ${totalBarValue} total trials`;
      },
      label: function(context) {
        let label = context.dataset.label || '';

        if (label) {
            label += ': ';
        }
        if (context.parsed.x !== null) {
            label += context.parsed.x.toLocaleString("en-US") + ' trials';
        }
        return label;
      },
      labelColor: function(context) {
        return {
          backgroundColor: context.dataset.backgroundColor,
          borderWidth: 0,
          borderRadius: 4,
        }
      },
    }
  };

  return (
    <ChartContainer width={chartWidth} height={chartHeight}>
      <Bar
        width={"100%"}
        height={"100%"}
        type="bar"
        data={horizontalData}
        options={{
          indexAxis: "y",
          scales: {
            x: {
              stacked: true,
              grid: { drawOnChartArea: false },
              grace: '10%', // extra space between longest-bar and grid-boundary. 
              ticks: {
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
            y: {
              stacked: true,
              grid: { drawOnChartArea: false },
            },
          },
          responsive: true,
          
          tooltips: {
            mode: "index",
            intersect: false,
          },
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                generateLabels: chart => chart.data.datasets.map((labelData, index) => ({
                  ...labelData,
                    text: labelData.label.length > LEGEND_TEXT_MAX_LENGTH ? labelData.label.substr(0,LEGEND_TEXT_MAX_LENGTH) + '...' : labelData.label,
                    fillStyle: labelData.backgroundColor,
                    datasetIndex: index,
                    hidden: !chart.getDatasetMeta(index).visible,
                    lineWidth: 0,
                    fontColor: "#666666",
                    useBorderRadius: true,
                    borderRadius: 2,
                  }))
              },
              onHover: function(event, legendItem, legend) {
                setTooltipData({...tooltipData,
                                label: legendItem.label,
                                color: legendItem.fillStyle,
                                point: {x: event.x, y: event.y + 15}})
              },
              onLeave: function() {
                setTooltipData({...tooltipData, 
                                    label: '', 
                                    color: '', 
                                    point: {x: 0, y: 0}
                                });
              }
            },
            tooltip: tooltipConfig,
            datalabels: {
              display: false,
            },
          },
        }}
      />
      {tooltipData && (
          <Tooltip
              isVisible={ tooltipData.label !== 'undefined' }
              point={tooltipData.point}
              >
              <MdSquare 
                  size={'18px'}
                  fill={tooltipData.color}
                  />
              <span>
                  {tooltipData.label}
              </span>
          </Tooltip>
      )}
    </ChartContainer>
  );
};

export default HorizontalBarChart;
