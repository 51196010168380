import { 
    WiserLogo, 
    InfoAreaForm, 
    InfoAreaHeadingContainer, 
    InfoAreaSubHeading, 
    MInfoAreaForm,
    MInfoAreaHeading,
    MInfoAreaSubHeading,
    MInputFieldsContainer
} from "./styles";

import ProductLogo from "../../assets/image/gaa-logo.png";
import { 
    Error, 
    InputField, 
    RoundedButton, 
    RoundedInputField, 
    TextButton
} from "components";
import Layout from '../../layout/authLayout'
import { useNavigate } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MGaaLogo } from "./styles/style.Auth.Mobile";
import { TrademarkLogo } from "./styles/style.Auth.Screen";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateField } from "services/Utils/Validator";
import { forgotPassword } from "store/Requests/auth";
import { ROUTE_HOME, ROUTE_LOGIN } from "services/Constants/constant";
import { handleGAAIconClick } from "./utils";


function ForgotPassword(props) {

    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const isDeviceMobile = width < height;

    const emailInputRef = useRef(null);
    const SubmitButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const FORM_FIELD_TYPE_email = "email";
    const FORM_FIELD_PLACEHOLDER_email = "Company email ID";
    const TEXT_submit = 'Submit';
    const TEXT_back_to_page = "< back";
    const TEXT_alert_message = "Password reset link has been sent to your Email ID. Kindly check your email.";
    const TEXT_FORM_subheading = "Please enter your company credentials associated to your account. You will receive an email message with instructions on how to reset your password.";

    const [ data, setData ] = useState({ email: "" });
    const [ loading, setLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ focusedFields, setFocusedFields ] = useState({
        email: false,
    });

    const [ errorMessages, setErrorMessages ] = useState({ 
        "areValid": true,
        "errors": {
            "email": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleInputChange = (label, event) => {
        setData({
            ...data,
            [label]: event.target.value
        });
    }

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
    }

    const validateInputFields = (emailRef) => {
        
        let output = { areValid: true, errors: ""}

        const emailValidationResult = validateField(FORM_FIELD_TYPE_email, [emailRef]);

        output = { 
            areValid: emailValidationResult.isValid, 
            errors: { 
                "email": emailValidationResult
        }}

        return output;
    }

    useEffect(()=>{
        if (didMountRef.current) {
            const areInputFieldsValid = validateInputFields(emailInputRef);
            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: areInputFieldsValid.errors.email.isValid, 
                            error: areInputFieldsValid.errors.email.error
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            } else {
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: true, 
                            error: null
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            }
        } else {
            didMountRef.current = true;
            SubmitButtonRef.current.disabled = true;
        }
    },[data]);

    const handleSubmit = () => {

        if(isSubmitting == false)
            return;
        
        setLoading(true);
        dispatch(
            forgotPassword({     
                email: data.email
            })
        )
        .unwrap()
        .then(resJson => {
            setLoading(false);
            if(resJson.success){
                alert(TEXT_alert_message)
                navigate(ROUTE_LOGIN);
            }
        })
        .catch(error => {
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        })
        .finally(() => setLoading(false));
    }


    return (
        <Layout
            isLoaderDisplay={loading}
            footerRelative={true}
            isDeviceMobile={isDeviceMobile}
        >
            {
                isDeviceMobile 
                ?   <>
                        <MGaaLogo onClick={handleGAAIconClick}/>
                        <MInfoAreaForm>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <MInfoAreaHeading>Clinical Trials WISE-R</MInfoAreaHeading>
                                <TrademarkLogo />
                            </div>
                            <MInfoAreaSubHeading>{TEXT_FORM_subheading}</MInfoAreaSubHeading>
    
                            <MInputFieldsContainer>
                                <RoundedInputField 
                                    placeholder={FORM_FIELD_PLACEHOLDER_email}
                                    type={FORM_FIELD_TYPE_email}
                                    required={true}
                                    value={data.email}
                                    innerRef={emailInputRef}
                                    className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                                    onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_email, e)}}
                                    onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, true)}}
                                    onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, false)}}
                                    keyDownCallback={handleSubmit}
                                />

                                <Error 
                                    isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) }
                                    error={ errorMessages.errors.email.error }
                                    margin={'0.5vh 3vw'}
                                />
                                <Error 
                                    isVisible={ !errorMessages.errors.others.isValid }
                                    error={ errorMessages.errors.others.error }
                                    margin={'0.5vh 3vw'}
                                />
                            </MInputFieldsContainer>

                            <RoundedButton 
                                height={'5.2vh'}
                                fontSize={'1.8rem'}
                                textColor={"white"}
                                backgroundColor={'#23647C'}
                                borderRadius={'24px'}
                                disabled={ isSubmitting ? false : true }
                                innerRef={SubmitButtonRef}
                                handleClick={handleSubmit}
                            >
                                {TEXT_submit}
                            </RoundedButton>
                            <TextButton
                                style={{ marginTop: '2.5vh' }}
                                fontSize={'1.2rem'}
                                text={TEXT_back_to_page} 
                                handleClick={() => navigate(ROUTE_HOME)}
                            />
                        </MInfoAreaForm>
                    </>
                :  
                    <>
                        <InfoAreaForm>
                            <InfoAreaHeadingContainer>
                                <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" onClick={handleGAAIconClick} />
                                <div>Forgot Password</div>
                            </InfoAreaHeadingContainer>
                            <InfoAreaSubHeading>
                            {TEXT_FORM_subheading}
                            </InfoAreaSubHeading>

                            <InputField 
                                placeholder={FORM_FIELD_PLACEHOLDER_email}
                                type={FORM_FIELD_TYPE_email}
                                value={data.email}
                                innerRef={emailInputRef}
                                required={true}
                                className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                                onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_email, e)}}
                                onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, true)}}
                                onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, false)}}
                                keyDownCallback={handleSubmit}
                                inputOption={
                                    <div
                                        style={{ margin: '0 0 1vh 1vw' }}>
                                        <RoundedButton 
                                            width={'9vw'}
                                            height={'4.5vh'}
                                            textColor={"white"}
                                            backgroundColor={'#23647C'}
                                            borderRadius={'0.5rem'}
                                            innerRef={SubmitButtonRef}
                                            disabled={ isSubmitting ? false : true }
                                            handleClick={handleSubmit}
                                        >
                                            {TEXT_submit}
                                        </RoundedButton>
                                    </div>    
                                }
                            />
                            <Error 
                                isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) }
                                error={ errorMessages.errors.email.error }
                            />
                            <Error 
                                isVisible={ !errorMessages.errors.others.isValid }
                                error={ errorMessages.errors.others.error }
                            />
                        </InfoAreaForm>
                        <TextButton
                            style={{ position: 'absolute', bottom: '9vh'  }}
                            text={TEXT_back_to_page} 
                            handleClick={() => navigate(ROUTE_HOME)}
                        />
                    </>

            }
            
        </Layout>
    )
}

export default ForgotPassword