import React from "react";
import { HeaderContainer } from "../style";
import { HeaderBackBtn, SearchBar, UserNameBtn } from "../../components";
import { HEADER_PLACEHOLDER } from '../../services/Constants/constant';
import { useAuth } from "contexts/AuthContext";

const Header = (props) => {

    const { currentUser } = useAuth();

    return (
        <HeaderContainer
            isHeaderFixed={props.isHeaderFixed}>
            <HeaderBackBtn
                OnClickCallback={props.OnBackClickCallback}
            />
            <SearchBar
                placeholder={HEADER_PLACEHOLDER}
                handleSearchInput={props.searchInputCallback}
                handleSearchFocusCallback={props.searchFocusCallback}
                submitCallback={props.searchSubmitCallback}
            />
            <UserNameBtn
                userName={currentUser.name}
            />
        </HeaderContainer>
    );
}

export default Header;