import React, { useState } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { Legend, Tooltip } from "./style.map";
import { MdSquare } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr"
import { mapData } from "assets/static";
const geoUrl = mapData;

const ZOOM_MAP = {
    "ukr": 10, // Ukraine
    "lbn": 15, // Lebanon
    "arm": 14, // Armenia 
    "mda": 10, // Moldova
}


const WorldMap = (props) => {

    const [position, setPosition] = useState({ 
                                                coordinates: props.mapData?.zoomCoordinates, 
                                                zoom: props.isPiviCountrySelected 
                                                    ? determineZoom(props.mapData?.countryData[0].countryCode_ISO_A3)
                                                    : 1 
                                            });
    const [tooltipData, setTooltipData] = useState({ countryName: '', legendGroup: '', color: '', point: {x: 0, y: 0} });

    function handleMoveEnd(position) {
        setPosition(position);
    }

    function determineZoom(countryName) {
        if (countryName in ZOOM_MAP) return ZOOM_MAP[countryName];
        return 5;
    }

    function handleZoomIn() {
        if (position.zoom >= 4) return;
            setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
    }
    
    function handleZoomOut() {
        if (position.zoom <= 1.5) return;
            setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    }

    function handleMapReset(){
        setPosition((pos) => ({ ...pos, zoom: 1, coordinates: [0,0] }));
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '77%', height: '30vh' }}>
                <ComposableMap
                    style={{ width: '100%', height: '100%' }}>
                    <ZoomableGroup
                        zoom={position.zoom}
                        center={position.coordinates}
                        onMoveEnd={handleMoveEnd}
                    >
                        <Geographies 
                            geography={geoUrl}
                        >
                        {({ geographies }) => {
                            
                            return geographies.map((geo) => {
                                
                                const d = props.mapData?.countryData
                                                        .find((s) => s.countryCode_ISO_A3 === geo.id?.toLowerCase() || s.countryCode_ISO_A2.toLowerCase() === geo.properties['Alpha-2']?.toLowerCase())
                                const countryName = d?.countryName.split(" ")
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(" ");

                                return (
                                    <Geography
                                        key={geo.rsmKey} 
                                        geography={geo}
                                        stroke={"white"}
                                        strokeWidth={0.3}
                                        fill={ props.getColor(d) } 
                                        style={{
                                            default: {
                                                outline: 'none'
                                            },
                                            hover: {
                                                outline: 'none'
                                            },
                                            pressed: {
                                                outline: 'none'
                                            }
                                        }}
                                        onMouseEnter={(event) => {
                                            const color = props.getColor(d);
                                            const legendGroup = props.isPiviCountrySelected ? d?.value + " trials" : props.legendData.find((obj) => obj.color === color)?.label;

                                            setTooltipData({...tooltipData, 
                                                            countryName: `${countryName}`, 
                                                            legendGroup: `${legendGroup}`, 
                                                            color: `${props.getColor(d)}`, 
                                                            point: {x: event.pageX, y: event.pageY
                                                        }});
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipData({...tooltipData, 
                                                            countryName: '', 
                                                            legendGroup: '', 
                                                            color: '', 
                                                            point: {x: 0, y: 0}
                                                        });
                                        }}
                                    />
                                )
                            })
                        }}
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>

                {tooltipData && (
                    <Tooltip
                        // className="tooltip"
                        isVisible={ tooltipData.countryName !== 'undefined' }
                        point={tooltipData.point}
                        >
                        <MdSquare 
                            size={'18px'}
                            fill={tooltipData.color}
                            />
                        <div>
                            <span style={{ fontWeight: '600' }}>
                                {tooltipData.countryName}
                            </span>
                            {" " + tooltipData.legendGroup}
                        </div>
                    </Tooltip>
                )}
            </div>
            <Legend>
                    {
                        props.legendData.map((data) => {

                            return (
                                <div key={`legend-${data.label}`} style={{ display: 'flex', gap: '5px' }}>
                                    <MdSquare 
                                        size={'15px'}
                                        fill={data.color}
                                        />
                                    <div>{data.label}</div>
                                </div>
                            );
                        }) 
                    }
                    <div 
                        style={{display:'flex', alignItems:'center', gap:'.5vw', marginTop: '2vh'}}>
                        <button onClick={handleZoomOut} title="Zoom-Out">-</button>
                        <button onClick={handleZoomIn} title="Zoom-In">+</button>
                        <button onClick={handleMapReset} title="Reset Map"><GrPowerReset /></button>
                    </div>
            </Legend>

        </div>
    );
};

export default WorldMap;