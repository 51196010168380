import styled from 'styled-components';

export const NavBar = styled.nav`
    row-gap: 1.5vh;
    display: flex;
    overflow: auto;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 25px 0;
    margin-top: -20px;
`;

export const NavButton = styled.div`
    /* position: relative; */
    background-color: ${props => props.ifSelected ? "#468AAB" : "#F7F7F6"};
    color: ${props => props.ifSelected ? "#FAFAF9" : "#468AAB"};
    border: none;
    box-shadow: 3px 12px 20px 0px rgba(88, 158, 195, 0.15);
    border-radius: 26px;
    padding: 9px 22px;
    margin-right: 10px;
    /* max-height: 1vh; */
    overflow: hidden;
    flex: 0 0 10vw;
    white-space: nowrap;

    &:hover {
        ${props => !props.ifSelected &&
            'cursor: pointer; \
            background-color: #EEFCFE;'
        }
    }
`;

export const Circle = styled.div`
    position: absolute;
    top: -0.35rem;
    right: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #468AAB;
    color: #FAFAF9;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MainContainer = styled.main`
    font-size: 18px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: 2096;
    height: auto;
    max-width: 100%;
    margin-bottom: 48px;
`;

export const Box = styled.div`
    color: ${props => props.disabled ? '#6C707330' : '#23647C'};
    display: inline-block;
    box-sizing: border-box;
    padding: ${props => props.level === 1 ? "14px 25px 12px" : "10px 18px 8px"};
    border: ${props => props.level === 1 ? "" : 
        props.disabled ? '1px solid #6C707310' :
            props.match ? "1px solid #23647C50"
            :"1px solid #6AB1DA40"
    };
    background-color: ${props => props.match ? "#EEFCFE" : "#FAFAF9"};
    margin: 5px;
    width: ${props => props.level === 1 ? "33%" : "" };
    border-radius: 24px;
    box-shadow: 3px 12px 20px #589EC320;

    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.level === 1? "600" : "400"};

    &.current:not(:has(div.current:hover)):hover {
        ${props => !props.disabled ? 
            "cursor: pointer; \
            background-color: #EEFCFE; \
            border: 1.5px solid #23647C50;" 
            : "cursor: default;"
        }
    }

    &.current:not(:has(div.current:active)):active {
        ${props => !props.disabled && 
            "background-color: #DDEBF4; \
            color: #468AAB;"
        }
    }
`;

export const Title = styled.p`
    margin: 4px auto;
`;