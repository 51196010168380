import InfoBubBtn from "../../Buttons/infobubBtn";
import { Title } from "../style.cards";

import {
  ChartDisease,
  CollapsibleTileBody,
  CollapsibleTileHeader,
  CollapsibleTileHeaderOptions,
  CollapsibleTileStateIcon,
  CollapsibleTileWrapper,
} from "./style.collapsibleCard";
import { BsTriangleFill } from "react-icons/bs";

const CollapsibleTile = (props) => {
  return (
    <CollapsibleTileWrapper
      isTileOpen={props.isTileOpen}
      showBottomBorder={!props.isLastTile}
      onClick={() => props.handleTileClick(props.keyValue)}
    >
      <CollapsibleTileHeader>
        <Title>{props.title}</Title>
        <CollapsibleTileHeaderOptions>
          <CollapsibleTileStateIcon isTileOpen={props.isTileOpen}>
            <BsTriangleFill
              // size={'16px'}
              color="#23647C"
            />
          </CollapsibleTileStateIcon>
          <InfoBubBtn info={props.info} />
        </CollapsibleTileHeaderOptions>
      </CollapsibleTileHeader>
      {props.isTileOpen && <ChartDisease>{props.chartDisease}</ChartDisease>}
      <CollapsibleTileBody isTileOpen={props.isTileOpen}>
        {props.children}
      </CollapsibleTileBody>
    </CollapsibleTileWrapper>
  );
};

export default CollapsibleTile;
