import Footer from "./Footer/footer";
import { LayoutProvider } from "./style";

import {
  GreetingsArea,
  InfoArea,
  LogInContainer,
  GreetingsContainer,
  GreetingsHeading,
  GreetingsSubHeading,
  MBodyContainer,
  MInfoArea,
} from "../pages/Auth/styles";
import { TrademarkLogo } from "pages/Auth/styles/style.Auth.Screen";
import Loader from "./Loader/loader";

const Layout = (props) => {
  const TEXT_heading = "Clinical Trials WISE-R";
  const TEXT_sub_heading =
    "An AI life science platform that aggregates, analyzes & visualizes data to provide limitless dynamic insight from any functional perspective and level of detail";

  return (
    <LayoutProvider style={{ gap: "0" }}>
      <Loader isLoaderDisplay={props.isLoaderDisplay} />
      <LogInContainer isDeviceMobile={props.isDeviceMobile}>
        {props.isDeviceMobile ? (
          <>
            <MBodyContainer>
              <MInfoArea>{props.children}</MInfoArea>
            </MBodyContainer>
          </>
        ) : (
          <>
            <GreetingsArea>
              <GreetingsContainer>
                <div style={{ display: "flex", gap: "5px" }}>
                  <GreetingsHeading>{TEXT_heading}</GreetingsHeading>
                  <TrademarkLogo />
                </div>
                <GreetingsSubHeading>{TEXT_sub_heading}</GreetingsSubHeading>
              </GreetingsContainer>
            </GreetingsArea>
            <InfoArea>{props.children}</InfoArea>
          </>
        )}
      </LogInContainer>
      <Footer
        relative={props.footerRelative}
        // showSingleLine={props.isDeviceMobile}
      />
    </LayoutProvider>
  );
};

export default Layout;
