import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  options: {
    Disease: [],
    Sponsor: [],
    Locations: [],
    SurroundCountries: [],
    Phase: [],
    Active: [],
    Year_Started: [],
    Study_Type: [],
    Year_Completed: [],
  },
};

const options = createSlice({
  name: "option/clinical",
  initialState: defaultState,
  reducers: {
    updateOptions: (state, action) => {
      state.options[action.payload.optionKey] = action.payload.optionVal;
    },
  },
});

export const { updateOptions } = options.actions;

export default options.reducer;
