export const processNumberString = (number) => {
    number = Number(number);
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
};

export const numFormatter = (num) => {
    const numAbsolute = Math.abs(num);
    if (numAbsolute > 1000 && numAbsolute < 1000000) {
        return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (numAbsolute > 1000000 && 1000000000 > numAbsolute) {
        return (num/1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
    } else if (numAbsolute > 1000000000) {
        return (num/1000000000).toFixed(0) + 'B'; // convert to M for number from > 1 million 
    } else if (numAbsolute < 1000) {
        return num; // if value < 1000, nothing to do
    }
}