import React from "react";
import { HeaderContainer, HeaderTitle } from "../style";
import { HeaderBackBtn, SearchBar, UserNameBtn } from "../../components";
import { HEADER_PLACEHOLDER } from "../../services/Constants/constant";
import Logo from "../../assets/image/gaa-logo.png";
import { useAuth } from "contexts/AuthContext";

const Header = (props) => {
  const { currentUser } = useAuth();
  const order = props.order.split(" | ");

  const getComponentFromOrder = (element) => {
    switch (element) {
      case "back-button": {
        return (
          <HeaderBackBtn
            key={"back-button"}
            OnClickCallback={props.OnBackClickCallback}
          />
        );
      }
      case "gaa-logo": {
        return (
          <HeaderTitle key="gaa-logo">
            <img
              src={Logo}
              alt="Logo"
              style={{ height: "29px", marginRight: "5px" }}
            />
            Global Action Alliance
          </HeaderTitle>
        );
      }
      case "search-bar": {
        return (
          <SearchBar
            key={"search-bar"}
            placeholder={HEADER_PLACEHOLDER}
            showSearchIcon={true}
            handleSearchInput={props.searchInputCallback}
            handleSearchFocusCallback={props.searchFocusCallback}
            submitCallback={props.searchSubmitCallback}
          />
        );
      }
      case "username-button": {
        return (
          <UserNameBtn key={"username-button"} userName={currentUser.name} />
        );
      }

      default:
        return <div key="default"></div>;
    }
  };

  return (
    <HeaderContainer isHeaderFixed={props.isHeaderFixed}>
      {order.map((element) => {
        return getComponentFromOrder(element);
      })}
    </HeaderContainer>
  );
};

export default Header;
