import InfoBubBtn from "../Buttons/infobubBtn";
import {
  GridCardWrapper,
  TitleWrapper,
  Title,
  CardNoData,
} from "./style.cards";
import ErrorWrapper from "middleware/errorWrapper";

const GridCard = (props) => {
  return (
    <ErrorWrapper>
      <GridCardWrapper
        gridRow={props.gridRow}
        gridColumn={props.gridColumn}
        padding={props.padding}
      >
        {props.children && props.isDataAvailable ? (
          <>
            {props.isBlank != true && (
              <TitleWrapper>
                <Title>{props.title}</Title>
                <InfoBubBtn info={props.info} />
              </TitleWrapper>
            )}
            {props.children}
          </>
        ) : (
          <CardNoData>{props.noDataText}</CardNoData>
        )}
      </GridCardWrapper>
    </ErrorWrapper>
  );
};

export default GridCard;
