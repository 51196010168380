import { BsInfoCircle } from "react-icons/bs";
import { InfoBub, InfoBubTooltip } from './style.button';
import { useRef, useState } from "react";

const InfoBubBtn = (props) => {

    const [ coordinates, setCoordinates ] = useState({x: "0px", y: "0px"});
    const bubbleRef = useRef(null);

    return (
        <InfoBub 
            ref={bubbleRef}
            onMouseEnter={(event) => {
                setCoordinates({
                    ...coordinates,
                    x: bubbleRef.current.offsetLeft + (bubbleRef.current.offsetWidth * 1.5) + 'px', 
                    y: bubbleRef.current.offsetTop + bubbleRef.current.offsetHeight + 'px'
                })
            }}
            onMouseLeave={() => {
                setCoordinates({
                    ...coordinates,
                    x: "0px",
                    y: "0px"
                })
            }}
        >
            <BsInfoCircle />
            {
                props.info && 
                <InfoBubTooltip 
                    point={coordinates}
                >
                    <div>
                        {props.info}
                    </div>
                </InfoBubTooltip>
            }
        </InfoBub>
    );
}

export default InfoBubBtn;