import React, { useState, useEffect } from 'react';
import { DropdownWrapper, TopTitle, ArrowIcon, Menu } from './style';
import { BsTriangleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { filterActions as ClinicalActions } from "store/ClinicalTrials/filtersReducer";
import { getDiseaseHierarchy, regexMatch } from '../util';
import { useMemo } from 'react';
import { ROUTE_CLINICAL_TRIALS } from 'services/Constants/constant';


export function Dropdown(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { name, level, ifDisabled, searchText, children, disabled } = props;
    const [ open, setOpen ] = useState(false);

    // Determine if current Dropdown menu title is a match
    const match = useMemo(()=> {
        return searchText.length >= 3 && regexMatch(searchText, name);
    }, [searchText]);

    // Determine if any of its children is a match
    const childMatch = useMemo(() => {
        let result = false;
        if (searchText.length >= 3) {
            children.forEach(child => {
                if (regexMatch(searchText, child.name)) result = true;
            });
        }
        return result;
    }, [searchText]);


    const handleItemClick = (event) => {
        event.stopPropagation();
        setOpen((prev) => {
            if (ifDisabled) return false;
            return !prev;
        });
    }

    const handleDiseaseClick = (event, disease, level, ifTrials) => {
        event.stopPropagation();
        /* Clearing Filter values before CT-Page */
        dispatch(ClinicalActions.setDefault());

        if (!ifTrials) return;

        dispatch(ClinicalActions.changeFilter({ key: "Disease", value: [ disease ] }));
        const hierarchy = getDiseaseHierarchy(event, disease, level);
        navigate(ROUTE_CLINICAL_TRIALS(disease), { state: { hierarchy: hierarchy, level: `level_${level}`}});

    }

    return (
        <DropdownWrapper className={"current"} ifOpen={open} match={match} childMatch={childMatch}>
            <TopTitle style={{margin: "3px 0 5px 0"}} ifDisabled={ifDisabled} ifTrials={props.ifTrials} className={"current"}>
                <ArrowIcon onClick={handleItemClick} ifOpen={open} className={"arrow"}>
                    <BsTriangleFill />
                </ArrowIcon>
                <span 
                    style={{marginLeft: "10px"}} 
                    title={!props.ifTrials ? "No trials available." : undefined}
                    onClick={(!ifDisabled) ? (e) => handleDiseaseClick(e, name, level, props.ifTrials) : () => {}}
                >
                    {name}
                </span>
            </TopTitle>
            <Menu ifOpen={open}>
            { children.map((obj, index) => {
                const { name } = obj;
                return (
                    <Element
                        key={index}
                        name={name}
                        level={level+1}
                        searchText={searchText}
                        ifTrials={obj.ifTrials}
                    />
                )
            }) }
            </Menu>
        </DropdownWrapper>
    )
}

export function Element(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { name, level, searchText, ifTrials } = props;

    // Determine if a match for the current Dropdown menu title
    const match = useMemo(()=> {
        return searchText.length >= 3 && regexMatch(searchText, name);
    }, [searchText]);

    const handleDiseaseClick = (event, disease, level, ifTrials) => {
        event.stopPropagation();
        /* Clearing Filter values before CT-Page */
        dispatch(ClinicalActions.setDefault());

        if (!ifTrials) return;

        dispatch(ClinicalActions.changeFilter({ key: "Disease", value: [ disease ] }));
        const hierarchy = getDiseaseHierarchy(event, disease, level);
        navigate(ROUTE_CLINICAL_TRIALS(disease), { state: { hierarchy: hierarchy, level: `level_${level}`}});
    }

    return (
        <TopTitle style={{padding: "1% 0 1% 28px"}} ifDisabled={true} ifTrials={ifTrials} match={match}>
            <ArrowIcon ifOpen={false} className={"arrow"}>
                <BsTriangleFill />
            </ArrowIcon>
            <span 
                style={{marginLeft: "10px"}} 
                onClick={(e) => handleDiseaseClick(e, name, level, ifTrials)} 
                title={!ifTrials ? "No trials available." : undefined}
            >
                {name
            }</span>
        </TopTitle>
    )
}