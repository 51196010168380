import React from "react";
import {
  CardDetails,
  CardDetailsHeaderRow,
  CardDivider,
  CardHeaderRow,
  CardSummary,
  CardSummaryDescription,
  CardSummaryStatus,
  CardSummaryStatusText,
  CardSummaryTitle,
  CardTrialID,
  CardWrapper,
  Detail,
  DetailHeading,
  DetailWrapperH,
  DetailWrapperV,
  DetailsContainerH,
  DetailsContainerV,
  ShowResults,
  ContentContainer,
  FlexContainer,
  CardViewSource,
  StatisticalTabsContainer,
  StatisticalTab,
} from "../style.clinicalTrails.js";
import { Pagination } from "../../../components/index.js";
import { useNavigate } from "react-router";
import { getStatusColor } from "../utils/util.js";
import { ROUTE_INDIVIDUAL_TRIAL } from "services/Constants/constant.js";

const ClinicalTrialListUI = (props) => {
  const navigate = useNavigate();

  const generateStatisticalTabs = () => {
    return props.data.stats.map((element, index) => {
      return (
        <StatisticalTab key={element[1]}>
          <div>{element[0]}</div>
          <div>{element[1]}</div>
        </StatisticalTab>
      );
    });
  };

    const generateTrialCards = () => {
                
        return props.data.list.length > 0 ? 
            props.data.list.map((item, index) => {

                const [statusColor, statusTextColor] = getStatusColor(item.ifOngoing);
                const cardData = {
                    TrialID: item.TrialID,
                    StatusText: item.ifOngoing,
                    Title: item.Title,
                    Summary: item.Summary,
                    StartDate: item.Start_date ? item.Start_date : "-",
                    CompletionDate: item.Completion_date ? item.Completion_date : "-",
                    Source: item.Study_Result,
                    PrimarySponsor: item.Primary_sponsor ? item.Primary_sponsor : "-",
                    Phase: item.Phase ? item.Phase : "-",
                    StudyType: item.Study_type ? item.Study_type : "-",
                    TargetSize: item.Target_size ? item.Target_size : "-",
                    AgeRange: item.Age_Range ? item.Age_Range : "-",
                    Conditions: item.Condition !== "No Data" && item.Condition.length > 0 ? item.Condition.join(", ") : "-",
                    Locations: item.Locations !== "Not available" ? item.Locations : "-",
                    Countries: item.Countries !== "Not available" ? item.Countries : "-"
                }
                
                return (
                    <CardWrapper
                        key={cardData.TrialID}>
                        <CardSummary>
                            <CardHeaderRow>
                                <CardSummaryStatus
                                    statusColor={statusColor}>
                                    <CardSummaryStatusText
                                        color={statusTextColor}>
                                        { cardData.StatusText }
                                    </CardSummaryStatusText>
                                </CardSummaryStatus>
                                <CardTrialID>{ cardData.TrialID }</CardTrialID>
                            </CardHeaderRow>
                            <CardSummaryTitle 
                                title={cardData.Title}
                                onClick={() => navigate(ROUTE_INDIVIDUAL_TRIAL(props.disease,cardData.TrialID), {state: props.locationState})}
                            >
                                {cardData.Title}
                            </CardSummaryTitle>
                            <CardSummaryDescription title={cardData.Summary}>{cardData.Summary}</CardSummaryDescription>
                        </CardSummary>
                        <CardDivider />
                        <CardDetails>
                            <CardDetailsHeaderRow>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                                    <div title={cardData.StartDate}>{`Start Date: ${cardData.StartDate}`}</div>
                                    <div title={cardData.CompletionDate}>{`Completion Date: ${cardData.CompletionDate}`}</div>
                                </div>
                                {
                                    cardData.Source !== "No Data" &&
                                    <CardViewSource
                                        handleClick={() => window.open(cardData.Source, "_blank", "noreferrer")}
                                    >
                                        View source here
                                    </CardViewSource>
                                }
                                
                            </CardDetailsHeaderRow>
                            <DetailsContainerV>
                                <div style={{  margin: '0 0.5vw 0 0' }}>
                                    <DetailHeading>Phase:</DetailHeading>
                                    <Detail title={cardData.Phase}>{cardData.Phase}</Detail>
                                </div>
                                <DetailWrapperV>
                                    <DetailHeading>Study Type:</DetailHeading>
                                    <Detail title={cardData.StudyType}>{cardData.StudyType}</Detail>
                                </DetailWrapperV>
                                <DetailWrapperV>
                                    <DetailHeading>Target Size:</DetailHeading>
                                    <Detail title={cardData.TargetSize}>{cardData.TargetSize}</Detail>
                                </DetailWrapperV>
                                <DetailWrapperV style={{  }}>
                                    <DetailHeading>Age Range:</DetailHeading>
                                    <Detail title={cardData.AgeRange}>{cardData.AgeRange}</Detail>
                                </DetailWrapperV>
                            </DetailsContainerV>

                            <DetailsContainerH>
                                <DetailWrapperH>
                                    <DetailHeading>Primary Sponsor:</DetailHeading>
                                    <Detail title={cardData.PrimarySponsor}>{cardData.PrimarySponsor}</Detail>
                                </DetailWrapperH>
                                <DetailWrapperH>
                                    <DetailHeading>Conditions: </DetailHeading>
                                    <Detail title={cardData.Conditions}>{cardData.Conditions}</Detail>
                                </DetailWrapperH>
                                <DetailWrapperH>
                                    <DetailHeading>Clinical Trial Site: </DetailHeading>
                                    <Detail title={cardData.Locations}>{cardData.Locations}</Detail>
                                </DetailWrapperH>
                                <DetailWrapperH>
                                    <DetailHeading>Countries: </DetailHeading>
                                    <Detail title={cardData.Countries}>{cardData.Countries}</Detail>
                                </DetailWrapperH>
                            </DetailsContainerH>
                        </CardDetails>
                    </CardWrapper>
                )
            })
        : 
            <h1>No Trials Returned for the Given Filters</h1>
    }

  return (
    <ContentContainer ref={props.innerRef}>
      <StatisticalTabsContainer isVisible={props.areTabsVisible}>
        {generateStatisticalTabs()}
      </StatisticalTabsContainer>
      <FlexContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {props.data.list.length > 0 ? (
            <ShowResults>{`Show ${
              (props.data.currentPage - 1) * 10 + 1
            } - ${Math.min(
              props.data.countOfFilteredTrials,
              props.data.currentPage * 10
            )} of ${
              props.data.countOfFilteredTrials
            } clinical trials`}</ShowResults>
          ) : (
            <ShowResults>{`Show 0 - 0 of 0 clinical trials`}</ShowResults>
          )}
          <main>{generateTrialCards()}</main>
        </div>

        <Pagination
          numberOfPages={props.data.pageCount}
          currentPage={props.data.currentPage}
          paginate={props.paginate}
        />
      </FlexContainer>
    </ContentContainer>
  );
};

export default ClinicalTrialListUI;
