import { useState, useEffect, useRef } from "react";
import Header from "./Header/gHeader";
import Footer from "./Footer/footer";
import Breadcrumb from "./Breadcrumb/breadcrumb";
import { LINK } from "../services/Constants/constant";
import {
    LayoutProvider,
    BodyContainer,
    BreadcrumbWrapper,
    TextButton,
    TitleContainer,
} from "./style";
import Loader from "./Loader/loader";

const Layout = (props) => {

    return (
        <LayoutProvider>
            <Loader isLoaderDisplay={props.isLoaderDisplay} />
            <Header
                key={props.headerOrder}
                isHeaderFixed={true}
                order={props.headerOrder}
                OnBackClickCallback={props.onBackClickCallback}
                searchInputCallback={props.handleSearchInput}
            />
            <BodyContainer marginTop={"7vh"}>
                <BreadcrumbWrapper>
                    <Breadcrumb />
                    <TextButton href={`${LINK}`}>Share Additional Data</TextButton>
                </BreadcrumbWrapper>
                <TitleContainer>{props.title}</TitleContainer>
                {props.children}
            </BodyContainer>
            <Footer relative={props.footerRelative} />
        </LayoutProvider>
    );
};

export default Layout;
