import styled from "styled-components";

export const GridCardWrapper = styled.div`
    box-sizing: border-box;
    border-radius: 15px;
    text-align: left;
    font-weight: 300;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    background-color: ${props=> props.theme.colors.generalWhite};
    width: 100%; 
    height: 100%;
    padding: ${props => props.padding ? props.padding : '1.2vw'};
    display: flex;
    flex-direction: column;
    gap: 1vh;


    grid-row: ${props=>props.gridRow};
    grid-column: ${props=>props.gridColumn} ;

    /* &:hover {
        background-color: ${props=> props.theme.colors.lightBlue};
        cursor: pointer;
    } */
`

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size:${props => props.theme.heading4.fontSize};
    font-weight:${props=> props.theme.heading4.fontWeight};
`

export const Title = styled.div`
    color: ${props => props.theme.colors.text.generalText};;
`

export const CardNoData = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;

    font-size:${props => props.theme.heading2.fontSize};
    font-weight:${props => props.theme.heading2.fontWeight};
    
    color: ${props => props.theme.colors.text.secondaryText};
    
`