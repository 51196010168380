//------------Layout----------------------
export const HEADER_PLACEHOLDER = `Search by Disease Name or Keyword`;
export const LINK = `mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data`;
export const LINK_M = `mailto:hansilla.alaigh@globalactionalliance.net`;
export const LINK_GAA = `https://www.gaawiser.com`;
export const LINK_GAA_about = LINK_GAA + `/about`;

//------------ Routes ----------------------
export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_PRE_REGISTER = "/pre-register";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_SET_PASSWORD = (token) => {
  return `/set-password/${token}`;
};
export const ROUTE_DISEASE_SELECTION = "/disease-selection";
export const ROUTE_CLINICAL_TRIALS = (disease) => {
  return `/disease-selection/${disease}`;
};
export const ROUTE_INDIVIDUAL_TRIAL = (disease, trialId) => {
  return `/disease-selection/${disease}/${trialId}`;
};

export const ROUTE_gaa_logo = "/assets/gaa-logo";

//------------Clinical Trails----------------
export const CLINICAL_TRAIL_FILTER_TAG = "CLINICAL_TRIALS";

export const FILTER_DELAY = 0; // in millie-seconds, times 1000 to convert to seconds
export const LEGEND_TEXT_MAX_LENGTH = 20;

// export const sunburnChartColor = [
//   "#A6C9D7",
//   "#659BB6",
//   "#468AAB",
//   "#33708D",
//   "#255166",
//   "#255166",
//   "#255166",
// ];

export const byPhaseColorArr = [
  "#A6C9D7",
  "#659BB6",
  "#468AAB",
  "#33708D",
  "#255166",
];
export const byAgeColorArr = [
  "#A8B97C",
  "#B4C788",
  "#D9E8B2",
  "#E7F0D0",
  "#E1F2DD",
];
export const bySampleSizeColorArr = [
  "#437E8D",
  "#73B8C9",
  "#84BAC5",
  "#B5E5F0",
  "#CAEEF8",
  "#D8F0F5",
];
export const byYearColorArr = [
  "#164050",
  "#659BB6",
  "#468AAB",
  "#33708D",
  "#255166",
];
export const bySponsorTypeStackedColorArr = [
  "#194D61",
  "#335f70",
  "#4c717f",
  "#66838e",
];

export const HORIZONTAL_BAR_DEFAULT_COLOR = [
  "#366672",
  "#437E8D",
  "#549EB0",
  "#70A9A3",
  "#82B09A",
  "#A1C4B3",
];

export const COLORS_MAP_LEGEND_1 = ["#255166", "#33708D", "#659BB6", "#A6C9D7"];
export const COLORS_MAP_LEGEND_2 = ["#417B8A", "#BEDFCE"];
export const DEFAULT_FALLBACK =
  "There was some error. Please try again later!!";
