import { media } from "../../../assets/static";
import styled from "styled-components";
import auth_background from "../../../assets/image/auth_background.png";
import gaaLogo from "../../../assets/image/gaa-circle-bg-logo.png";

export const MBodyContainer = styled.div`
    display: flex;
    justify-content: center;
    
    width: 100%;
    height: 100%;
    background-image: url(${auth_background});
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 2% 10%;
`;

export const MGaaLogo = styled.div`
    background-image: url(${gaaLogo});
    background-size: 100%;
    background-repeat: no-repeat;
    width: 17vw;
    height: 12.2vh;

    position: absolute;
    top: -6.1vh;
    left: 40%;
`

export const MInfoArea = styled.div`
    width: 63.5%;
    height: fit-content;

    display: flex;
    padding: 13% 7.5vw 7.25vh 7.5vw;
    margin: 23vh 0 15.7vh 0;
    font-size: ${props => props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};

    border-radius: 24px;
    background: rgba(250, 250, 249, 0.55);
    box-shadow: 3px 12px 20px 0px rgba(88, 158, 195, 0.15);
    backdrop-filter: blur(25px);
`

export const MInfoAreaForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const MInfoAreaHeading = styled.div`
    color: ${props => props.theme.colors.text.generalText};
    font-size: ${props => props.theme.heading0.fontSize};
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
`

export const MInfoAreaSubHeading = styled.div`
    color: ${props => props.theme.colors.text.generalText};
    font-size: ${props => props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    line-height: normal;
`

export const MInputFieldsContainer = styled.div`
    margin: 5% 0;
`