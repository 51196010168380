const ENDPOINT = "PRODUCTION"; // "LOCAL", "PRODUCTION"

function getAPIParams(ENDPOINT) {
  switch (ENDPOINT) {
    case "PRODUCTION":
      return ["https", "ct-wiser.net", "443"];
    case "LOCAL":
      return ["http", "localhost", "8080"];
    default:
      return ["http", "localhost", "8080"];
  }
}

const [MODE, PATH, PORT] = getAPIParams(ENDPOINT);

export { MODE, PATH, PORT };
