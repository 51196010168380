import React, { lazy, Suspense, Component } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Fallback } from "./components";
import { AuthProvider } from "./contexts/AuthContext";

import Diseases from "./pages/Disease-selection";

import PrivateRoute from "services/Utils/PrivateRoute";
import { CreateAccountForm, ForgotPassword, LogInForm, PreRegister, ResetPassword, SetPassword } from "./pages/Auth";
import { IndividualTrial } from "./pages/ClinicalTrials/index";
import { GaaLogo } from "pages/Assets";

const ClinicalTrials = lazy(() =>
  import("./pages/ClinicalTrials/index").then((module) => ({
    default: module.ClinicalTrials,
  }))
);

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Fallback />}>
        <Router>
            <AuthProvider>
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/assets/gaa-logo" element={<GaaLogo />} />

              <Route path="/login" element={<LogInForm />} />
              <Route path="/pre-register" element={<PreRegister />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/set-password/:token" element={<SetPassword />} />
              <Route path="/reset-password" element={<PrivateRoute><ResetPassword /></PrivateRoute>} />

              <Route path="/signup" element={<CreateAccountForm />} />
              <Route
                path="/disease-selection"
                element={
                  <PrivateRoute>
                    <Diseases />
                  </PrivateRoute>
                }
              />
              <Route
                path="/disease-selection/:disease"
                element={
                  <PrivateRoute>
                    <ClinicalTrials />
                  </PrivateRoute>
                }
              />
              <Route
                path="/disease-selection/:disease/:trialId"
                element={
                  <PrivateRoute>
                    <IndividualTrial />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to={"/disease-selection"}/>} />
            </Routes>
          </AuthProvider>
        </Router>
      </Suspense>
    );
  }
}

export default App;
